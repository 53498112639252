import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import { Link, Grid, Box } from '@magalu/stereo-ui/atoms';
export var ProductReviewCard = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 121px;\n  height: 162px;\n  gap: 0px;\n  border-radius: 8px;\n  opacity: 0px;\n  background: url(", ") no-repeat center lightgray;\n  background-size: cover;\n  position: relative;\n  display: flex;\n  justify-content: center;\n"])), function (props) {
  return props.image;
});
export var ProductReviewContainer = styled(Grid)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  overflow-x: auto;\n  padding: 16px;\n"])));
export var ProductReviewRating = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: absolute;\n  bottom: 8px;\n  left: 8px;\n  width: 100%;\n"])));
export var VideoBox = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 121px;\n  background: url(", ") no-repeat center lightgray;\n  background-size: cover;\n  border-radius: 15px;\n  height: 162px;\n"])), function (props) {
  return props.thumbnail;
});