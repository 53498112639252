export var handleMediaClick = function handleMediaClick(mediaProps, key, publish) {
  var processedMedia = mediaProps.filter(function (media) {
    return media.processed;
  });
  var selectedMedia = processedMedia[key];
  if (selectedMedia.mediaType === 'image') {
    publish('product:review:condensedMedia:image:click');
  } else {
    publish('product:review:condensedMedia:video:click');
  }
};